<template>
  <v-app style="background: #E3F2FD; background: linear-gradient(to bottom, #1976D2, #1E88E5, #E3F2FD);">
    
    <v-layout row wrap align-center justify-center >
      <v-flex xs12 lg4 class="ma-5">
        <v-card >
          <v-card-text class="pa-0">
            <v-layout
              row
              wrap
              align-center
              justify-center
              fill-height
              class="ma-0"
            >
              <!-- <v-flex lg6 class="text-center" v-if="resolutionScreen >= 500">
                <img
                  src="@/assets/landinglogo.png"
                  width="70%"
                  height="70%"
                  alt="One Box"
                />
              </v-flex> -->
              <v-flex style="background: white" class="pl-3 pr-3">
                <v-card-text class="text-left">
                    
                  <v-list two-line dense>
                    <v-list-item class="pl-0 pb-0">
                      <v-list-item-content>
                           <!-- <img src="@/assets/logo_onebox-horizontal-white.png" width="20%" height="20%" /> -->
                        <h1 style="color: #1976D2 ; " >ONE BOX</h1>
                        <h4 style="color: #1976D2; line-height: 40px;" >
                         เข้าสู่ระบบ One Platform เพื่อลงทะเบียนนิติบุคคล
                        </h4>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-menu transition="slide-y-transition" bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn fab text v-on="on" x-small>
                              <flag :iso="defaultflag" :squared="false" />
                            </v-btn>
                          </template>
                          <v-list nav dense>
                            <v-list-item
                              v-for="(item, i) in language"
                              :key="i"
                              @click="changelang(item.data)"
                            >
                              <v-list-item-action>
                                <flag :iso="item.flag" :squared="false" />
                              </v-list-item-action>
                              <v-list-item-title>{{
                                $t(item.name)
                              }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>

                  <!-- <img
                  src="@/assets/logo_onebox-horizontal-png.png"
                  width="45%"
                  height="45%"
                  alt="One Box"
                />
                  -->
                </v-card-text>
                <!-- <v-card-text>
                  <b>{{ $t("username") }}</b>
                  <v-text-field
                    class="mt-2"
                    outlined
                    solo
                    hide-details
                    flat
                    color="#174966"
                    prepend-inner-icon="account_box"
                    dense
                    @keyup.enter="login"
                    :disabled="loading"
                    v-model.trim="username"
                  ></v-text-field>
                  <br />
                  <b>{{ $t("password") }}</b>
                  <v-text-field
                    class="mt-2"
                    prepend-inner-icon="lock"
                    outlined
                    solo
                    color="#174966"
                    flat
                    dense
                    hide-details
                    :disabled="loading"
                    v-model.trim="password"
                    @keyup.enter="login"
                    @click:append="showpassword = !showpassword"
                    :type="showpassword ? 'text' : 'password'"
                    :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                  ></v-text-field>
                  <div class="pt-3 text-right">
                    <a
                      :href="urloneid + '/type_forgot_password'"
                      style="text-decoration: none;"
                      target="_blank"
                      >{{ $t("forgotpassword") }}</a
                    >
                  </div>
                </v-card-text>
                <v-card-text class="pt-0">
                  <v-btn
                    color="primary"
                    block
                    class="elevation-1 white--text"
                    @click="login"
                    :disabled="loading"
                    :loading="loading"
                  >
                    <b>{{ $t("login") }}</b>
                  </v-btn>
                </v-card-text> -->
                <v-card-text >
                  <b>{{ $t("username") }}</b>
                  <v-text-field
                    class="mt-2"
                    outlined
                    solo
                    hide-details
                    flat
                    color="#174966"
                    prepend-inner-icon="account_box"
                    dense
                    @keyup.enter="login"
                    :disabled="loading"
                    v-model.trim="username"
                  ></v-text-field>
                  <br />
                  <b>{{ $t("password") }}</b>
                  <v-text-field
                    class="mt-2"
                    prepend-inner-icon="lock"
                    outlined
                    solo
                    color="#174966"
                    flat
                    dense
                    hide-details
                    :disabled="loading"
                    v-model.trim="password"
                    @keyup.enter="login"
                    @click:append="showpassword = !showpassword"
                    :type="showpassword ? 'text' : 'password'"
                    :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                  ></v-text-field>
                  <div class="pt-3 text-right">
                    <a
                      :href="urloneid + '/type_forgot_password'"
                      style="text-decoration: none;"
                      target="_blank"
                      >{{ $t("forgotpassword") }}</a
                    >
                  </div>
                </v-card-text>
                <v-card-text class="pt-0 text-lg-center text-md-center text-sm-center" >
                  <v-btn
                    block
                    color="primary"
                    class="elevation-1 white--text"
                    @click="login"
                    :disabled="loading"
                    :loading="loading"
                  >
                    <b>{{ $t("login") }}</b>
                  </v-btn>
                </v-card-text>
                <v-card-text class="pt-0 text-lg-center text-md-center text-sm-center" >
                    <v-btn
                    block
                    color="primary"
                    outlined
                    class="elevation-1 white--text mr-1"
                    @click="goback"
                    :disabled="loading"
                    
                  >
                    <b>{{ $t("goback") }}</b>
                  </v-btn>
                </v-card-text>
               
                <br />
                <!-- <v-divider></v-divider> -->
                <!-- <v-card-text class="text-center"> -->
                      <!-- :href="urloneid + '/register'" -->
                  <!-- <span>
                    {{ $t("createaccount1") }}?
                    <a
                      style="text-decoration: none;"
                      @click="$router.push('/register')"
                      target="_blank"
                      >{{ $t("createaccount2") }}</a
                    >
                  </span> -->
                <!-- </v-card-text> -->
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapGetters, mapState } from "vuex";
import "sweetalert2/src/sweetalert2.scss";
const loginsso = () => import("../components/optional/dialog-loginsso.vue");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
  components: { loginsso },
  data: function() {
    return {
      defaultflag:"",
      loginssooneid: true,
      dialogsso: false,
      ssocode: "",
      intervalSSO: "",
      checkshow: false,
      language: [
        {
          flag: "us",
          name: "languages.english",
          data: "en"
        },
        {
          flag: "th",
          name: "languages.thai",
          data: "th"
        }
      ],
      urloneid: process.env.VUE_APP_ONEID,
      loader: true,
      loading: false,
      username: "",
      password: "",
      showpassword: false,
      dialog: false
    };
  },
  computed: {
    ...mapGetters({
      dataBusinessProfile: "dataBusinessProfile",
      dataAccesstoken: "dataAccesstoken",
      dataLoginDefault:"dataLoginDefault"
    }),
    getcheck() {
      let check = localStorage.getItem("show");
      if (check === "true") {
        return true;
      } else {
        return false;
      }
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    }
  },
  methods: {
      goback(){
          this.$router.push("/");
      },
    show() {
      if (this.checkshow === "true") {
        console.log(this.checkshow);
        localStorage.setItem("show", this.checkshow);
        this.dialog = "false";
      } else {
        localStorage.setItem("show", this.checkshow);
        this.dialog = "false";
      }
    },
    login() {
      this.loading = true;

      let payload = {
        username: this.username,
        password: this.password
      };

      // this.$router.push('/mydrive')
      this.$store
        .dispatch("authorize_login", payload)
        .then(msg => {
          // console.log(msg);
          this.loading = false;
          if (msg.status === "Access Granted") {
            // ส่ง event ไปยัง Google Analytics
            this.$gtag.event('login', {
              event_category: "username",
            });

            //console.log("this.dataLoginDefault",this.dataLoginDefault,this.dataLoginDefault["account_category"]); 
              localStorage.setItem("stepregister", 2);
              this.$router.push("/registerbusiness");
            
          } else if (msg.code === "LN001") {
            Toast.fire({
              icon: "error",
              title: "Username หรือ Password ไม่ถูกต้อง"
            });
          } else if (msg.code === "ER401") {
            Toast.fire({
              icon: "error",
              title: "Username หรือ Password ไม่ถูกต้อง"
            });
          } else if (msg.msg === "ER401: Authorization is wrong") {
            Toast.fire({
              icon: "error",
              title: "Username หรือ Password ไม่ถูกต้อง"
            });
            this.loader = false;
          } else {
            Toast.fire({ icon: "error", title: msg.msg });
          }

          
        })
        .catch(err => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ "
          });
          this.loading = false;
        });
    },
    changelang(parameter) {
      localStorage.setItem("lang", parameter);
      this.$i18n.locale = parameter;
      let dflag = this.language.findIndex(v => v.data === parameter);
      this.defaultflag = this.language[dflag]["flag"];
    },

    // async loginsso(code) {
    //   let payload = {
    //     token: code
    //   };
    //   this.loader = true;
    //   this.axios
    //     .post(
    //       process.env.VUE_APP_SERVICE_AUTHORIZE_SSO +
    //         "/api/login_by_token_oneid",
    //       payload
    //     )
    //     .then(res => {
    //       if (res.data.status == "OK") {
    //         localStorage.setItem("token", res.data.result["accesstoken"]);
    //         this.$store.dispatch("authorize_granted", res.data.result);
    //         if(this.dataLoginDefault !== ""){
    //         if(this.dataLoginDefault["account_category"] === "Business"){
    //            console.log(this.dataLoginDefault["account_category"]);
    //           this.loginbusiness_default();
    //       }
    //         }else{
    //         // this.$cookies.remove('ssocode')
    //         this.$router.push("/mydrive");
    //         }
    //       } else {
    //         this.$store.dispatch("authorize_denied");
    //         // this.$cookies.remove('ssocode')
    //         this.loader = false;
    //         // this.dialogsso = true
    //         // this.intervalSSO = setInterval(() => { this.ssocode = this.$cookies.get('ssocode') }, 800);
    //       }
    //     });
    // },
    // loginbusiness_default(){
    //    let payload = {
    //         taxid: this.dataLoginDefault.id_card_num,
    //         accesstoken: this.dataAccesstoken,
    //         biz_id:this.dataLoginDefault.id
            
    //       };

    //          this.$store.dispatch("switchaccount_business", payload).then((msg) => {
    //         if (msg["status"] === "Business Access Denied") {
              
    //           console.log("msg", msg);
    //           if (msg["msg"] === "ER401: Authorization is wrong") {
    //             this.$router.push("logout");
    //           } else {
    //             // this.$store.dispatch("switchaccount_citizen").then(msg => {
    //             //   this.$emit("loadfile");
    //             //   this.$emit("callstorage");
    //             //   this.$router.push({ name: "myfiles" });
    //             // });
    //           }
    //         } else {
    //           this.$router.push({ path: "/directory/" + this.dataLoginDefault["my_folder_id"] });
    //           //this.$router.push({ name: "myfiles" });
    //         }
    //       });
    // },
    // loginbusiness(path, taxid) {
    //   this.loading = true;
    //   let payload = {
    //     taxid: taxid,
    //     accesstoken: this.dataAccesstoken
    //   };
    //   this.$store.dispatch("switchaccount_business", payload).then(msg => {
    //     if (msg["msg"] === "ER401: Authorization is wrong") {
    //       console.log(msg);
    //       this.loader = false;
    //     } else {
    //       const pobj = JSON.stringify(payload);
    //       //sessionStorage.removeItem(pobj);
    //       sessionStorage.setItem("biz_active", pobj);
    //       if (path !== null || path !== undefined || path === "") {
    //         this.$router.push({
    //           name: path,
    //           query: {
    //             type: 2,
    //             sourcesystem: "ETAX",
    //             systemid: this.$route.query.systemid,
    //             fromdate: this.$route.query.crefromdate,
    //             todate: this.$route.query.crefromdate,
    //             fromdatedoc: this.$route.query.docfromdate,
    //             todatedoc: this.$route.query.doctodate,
    //             doctype: this.$route.query.doctype,
    //             doc_no_start: this.$route.query.docnostart,
    //             doc_no_end: this.$route.query.docnoend,
    //             filename: this.$route.query.filename,
    //             fileextension: this.$route.query.filetype,
    //             sendername: this.$route.query.sellername,
    //             sellerBranchid: this.$route.query.sellerbranchid,
    //             sellerTaxid: this.$route.query.sellertaxid,
    //             receiver_name: this.$route.query.buyername,
    //             receiverTaxid: this.$route.query.buyertaxid,
    //             receiverBranchid: this.$route.query.buyerbranchid
    //           }
    //         });
    //       } else {
    //         this.$router.push({ name: "myfiles" });
    //       }
    //     }
    //   });
    // },
    // fn_loginoneid() {
    //   location.assign(process.env.VUE_APP_ONEIDSSO);
    // }

  },
  watch: {
    ssocode: function(val) {
      if (val === null) {
        console.log(val);
        this.loginsso(val);
      } else {
        // this.dialogsso = false
        this.loginsso(val);
      }
    }
  },
  mounted() {
    // if (process.env.NODE_ENV === "production") {
    //   this.loginssooneid = true;
    // } else {
    //   this.loginssooneid = false;
    // }

    
   // setTimeout(() => {
      this.changelang(localStorage.getItem("lang"));
      console.log(this.$route.query);
    //   if ("authorizebydomain" in this.$route.query) {
    //     if ("onemailtoken" in this.$route.query) {
    //       localStorage.removeItem("color");
    //       let email = {
    //         business_email: this.$route.query.authorizebydomain,
    //         token: this.$route.query.onemailtoken
    //       };
    //       this.$store
    //         .dispatch("authorize_login_business_email_withtoken", email)
    //         .then(msg => {
    //           if (msg.status === "Access Granted") {
    //             if (this.$route.query.color !== undefined) {
    //               localStorage.setItem("color", this.$route.query.color);
    //             }
    //             this.$router.push("/mydrive");
    //             setTimeout(() => {
    //               this.loader = false;
    //             }, 500);
    //           } else {
    //             this.loader = false;
    //             Toast.fire({
    //               icon: "error",
    //               title: msg.msg
    //             });
    //           }
    //         })
    //         .catch(error => {
    //           this.loader = false;
    //           console.log(error);
    //         });
    //     } else {
    //       localStorage.removeItem("color");
    //       let email = { business_email: this.$route.query.authorizebydomain };
    //       this.$store
    //         .dispatch("authorize_login_business_email_v2", email)
    //         .then(msg => {
    //           if (msg.status === "Access Granted") {
    //             if (this.$route.query.color !== undefined) {
    //               localStorage.setItem("color", this.$route.query.color);
    //             }
    //             this.$router.push("/mydrive");
    //             setTimeout(() => {
    //               this.loader = false;
    //             }, 500);
    //           } else if (msg.code === "LN001") {
    //             Toast.fire({
    //               icon: "error",
    //               title: "Username หรือ Password ไม่ถูกต้อง"
    //             });
    //             this.loader = false;
    //           } else if (msg.code === "ER401") {
    //             Toast.fire({ icon: "error", title: "ไม่พบข้อมูลผู้ใช้งาน" });
    //             this.loader = false;
    //           } else {
    //             Toast.fire({ icon: "error", title: msg.msg });
    //             this.loader = false;
    //           }
    //         })
    //         .catch(error => {
    //           this.loader = false;
    //           console.log(error);
    //         });
    //     }
    //   } else if ("code" in this.$route.query) {
    //     // this.$cookies.set('ssocode',this.$route.query.code);
    //     this.ssocode = this.$route.query.code;
    //     // clearInterval(this.intervalSSO)
    //   } else if ("goto" in this.$route.query) {
    //     this.loader = false;
    //   } else {
        // this.$store
        //   .dispatch("authorize_checking")
        //   .then(msg => {
        //     // console.log(msg);
        //     if (msg.status === "Access Granted") {
        //       this.$router.push("/login-register");
        //       // this.loader = false;
        //     } else if (msg.code === "LN001") {
        //       Toast.fire({
        //         icon: "error",
        //         title: "Username หรือ Password ไม่ถูกต้อง"
        //       });
        //       this.loader = false;
        //     } else {
        //       // Toast.fire({ icon: "error", title: msg.msg });
        //       this.loader = false;
        //       // this.dialogsso = true;
        //       // this.intervalSSO = setInterval(() => { this.ssocode = this.$cookies.get('ssocode') }, 800);
        //     }
        //   })
        //   .catch(err => {
        //     setTimeout(() => {
        //       this.dialog = true;
        //     }, 800);
        //     console.log(err);
        //     //  this.dialog = true;
        //     this.loader = false;
        //   });
     // }
    //}, 100);
  }
};
</script>

<style></style>
